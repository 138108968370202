import React, { Component } from "react";
import { Link } from "react-router-dom";

const ServiceListContent = [
  {
    image: "image-2",
    // category: "Services",
    title: "Shipping",
    link: "/shipping",
  },
  {
    image: "image-3",
    // category: "Services",
    title: "Pickup and Delivery",
    link: "/pickup-n-delivery",
  },
  {
    image: "image-1",
    // category: "Services",
    title: "Packaging",
    link: "/packaging",
  },
  {
    image: "image-4",
    // category: "Services",
    title: "Door to Door",
    link: "/door-to-door",
  },
  {
    image: "image-3",
    // category: "Services",
    title: "Warehouse Facility (USA)",
    link: "/warehouse-facility",
  },
  //   {
  //     image: "image-4",
  //     category: "Services",
  //     title: "Customs Duties: How to Calculate and Minimize Costs",
  //     link: "/blog-details5",
  //   },
  //   {
  //     image: "image-1",
  //     category: "Services",
  //     title: "Essential Documents for Smooth International Trade",
  //     link: "/blog-details6",
  //   },
  //   {
  //     image: "image-2",
  //     category: "Services",
  //     title: "Choosing the Right Incoterms for Your Shipments",
  //     link: "/blog-details7",
  //   },
  //   {
  //     image: "image-3",
  //     category: "Services",
  //     title: "Optimizing Supply Chain Efficiency in Import-Export",
  //     link: "/blog-details8",
  //   },
  //   {
  //     image: "image-4",
  //     category: "Services",
  //     title: "Navigating Tariffs and Trade Barriers: A Comprehensive Guide",
  //     link: "/blog-details9",
  //   },
  //   {
  //     image: "image-3",
  //     category: "Services",
  //     title: "E-commerce and Global Trade: Expanding Market Reach",
  //     link: "/blog-details10",
  //   },
  //   {
  //     image: "image-4",
  //     category: "Services",
  //     title: "Customs Duties: How to Calculate and Minimize Costs",
  //     link: "/blog-details11",
  //   },
  //   {
  //     image: "image-1",
  //     category: "Services",
  //     title:
  //       "Understanding Trade Finance: Funding Options for Import-Export Businesses",
  //     link: "/blog-details12",
  //   },
  //   {
  //     image: "image-2",
  //     category: "Services",
  //     title:
  //       "Emerging Market Opportunities: A Look at the Next Frontier for Importers and Exporters",
  //     link: "/blog-details13",
  //   },
  //   {
  //     image: "image-3",
  //     category: "Services",
  //     title: "Pros and Cons of Dropshipping in International E-commerce",
  //     link: "/blog-details14",
  //   },
  //   {
  //     image: "image-4",
  //     category: "Services",
  //     title: "Photography and Videography Gear",
  //     link: "/blog-details15",
  //   },
  //   {
  //     image: "image-3",
  //     category: "Services",
  //     title: "A Deep Dive into Free Trade Zones: Benefits and Best Practices",
  //     link: "/blog-details16",
  //   },
  //   {
  //     image: "image-4",
  //     category: "Services",
  //     title: "The Future of Trade: Trends Shaping Global Commerce",
  //     link: "/blog-details17",
  //   },
];

class ServiceList extends Component {
  render() {
    const { column, styevariation } = this.props;
    const list = ServiceListContent.slice(0, this.props.item);
    return (
      <React.Fragment>
        {list.map((value, index) => (
          <div className={`${column}`} key={index}>
            <div className={`portfolio ${styevariation}`}>
              <div className="thumbnail-inner">
                <div className={`thumbnail ${value.image}`}></div>
                <div className={`bg-blr-image ${value.image}`}></div>
              </div>
              <div className="content">
                <div className="inner">
                  <p>{value.category}</p>
                  <h4>
                    <a>{value.title}</a>
                  </h4>
                  <div className="portfolio-button">
                    <a className="rn-btn" href={value.link}>
                      View Details
                    </a>
                  </div>
                </div>
              </div>
              {/* <Link className="link-overlay" to="/blog-details"></Link> */}
            </div>
          </div>
        ))}
      </React.Fragment>
    );
  }
}
export default ServiceList;
