import React, { Component } from "react";
import PageHelmet from "../component/common/Helmet";
import ModalVideo from "react-modal-video";
import {
  FaTwitter,
  FaInstagram,
  FaFacebookF,
  FaLinkedinIn,
} from "react-icons/fa";
import ScrollToTop from "react-scroll-up";
import { FiChevronUp } from "react-icons/fi";
import Header from "../component/header/Header";
import Footer from "../component/footer/Footer";
import BrandTwo from "../elements/BrandTwo";
import { FiCast, FiLayers, FiUsers, FiCheck } from "react-icons/fi";

const SocialShare = [
  { Social: <FaFacebookF />, link: "https://www.facebook.com/" },
  { Social: <FaLinkedinIn />, link: "https://www.linkedin.com/" },
  { Social: <FaInstagram />, link: "https://www.instagram.com/" },
  { Social: <FaTwitter />, link: "https://twitter.com/" },
];

class PortfolioDetails7 extends Component {
  constructor() {
    super();
    this.state = {
      isOpen: false,
    };
    this.openModal = this.openModal.bind(this);
  }
  openModal() {
    this.setState({ isOpen: true });
  }
  render() {
    let description = `Packaging plays an important role in global trade because it affects the safety, integrity, and efficiency of the supply chain. If the items are packaged properly and start for the move, then they may be damaged or lost during the move. So, it is important to ensure the best packaging with quality packing material. Your trusted partner, ArinTractorwala, does not damage your items because we use quality packing material. We at ArinTractorwala are a renowned export and import service provider in the USA. We take immense pride in giving you a range of packaging services to businesses. We make your international trade easier by handling every item during packaging. We ensure the secure and safe transportation of goods across the world. With excellence and commitment, we deliver the best packaging services to our clients and make a strong customer base. 
We understand that the need of every client is different, and they need particular packaging solutions. Our expert team works closely with customers to understand their needs, pack their products, and ensure cost-efficient solutions with the utmost protection. The good thing is that we use the highest packing quality material by meeting international standards. Our staff is well-trained to handle your packaging process and reduce the risk of damage during the move. We use the best practices and follow technical standards to secure your goods within packaging and prevent breakage and movement. We know that international trade involves numerous regulations regarding packaging. For this, we do the proper labeling and documentation of packed items, so no confusion leads, or you can make your move smooth. Our packaging services have become an integral part of our commitment to give you quality export and import solutions. You can contact us today to discuss how our packaging solutions benefit the international trade operations of your business.`;
    return (
      <React.Fragment>
        <PageHelmet pageTitle="Product Details" />

        <Header
          headertransparent="header--transparent"
          colorblack="color--black"
          logoname="ATT Star Icon.png"
        />

        {/* Start Breadcrump Area */}
        <div
          className="rn-page-title-area pt--120 pb--190 bg_image bg_image--4"
          data-black-overlay="7"
        >
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <div className="rn-page-title text-center pt--100">
                  <h2 className="title theme-gradient">Packaging</h2>
                  <p>“Secure and Reliable: Our Expert Packaging Solutions”</p>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* End Breadcrump Area */}

        <div className="rn-about-area ptb--120 bg_color--1">
          <div className="rn-about-wrapper">
            <div className="container">
              <div className="row row--35 align-items-center">
                <div className="col-lg-5">
                  <div className="thumbnail">
                    <img
                      className="w-100"
                      height={"600px"}
                      src="/assets/images/about/about-52.jpg"
                      alt="About Images"
                    />
                  </div>
                </div>
                <div className="col-lg-7 text-justify">
                  <div className="about-inner inner">
                    <div className="section-title">
                      {/* <h2 className="title">{title}</h2> */}
                      <p>{description}</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* Start Back To Top */}
        <div className="backto-top">
          <ScrollToTop showUnder={160}>
            <FiChevronUp />
          </ScrollToTop>
        </div>
        {/* End Back To Top */}

        <Footer />
      </React.Fragment>
    );
  }
}
export default PortfolioDetails7;
