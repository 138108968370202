import React, { Component, Fragment } from "react";
import CountUp from "react-countup";
import VisibilitySensor from "react-visibility-sensor";

class CounterOne extends Component {
  state = {
    didViewCountUp: false,
  };
  onVisibilityChange = (isVisible) => {
    if (isVisible) {
      this.setState({ didViewCountUp: true });
    }
  };
  render() {
    let Data = [
      {
        countNum: 1050,
        countName: "Successful Shipments Annually",
        countTitle:
          "Demonstrating our commitment to efficient logistics solutions.",
      },
      {
        countNum: 15,
        countName: "Trusted Partnerships",
        countTitle:
          "Collaborating with reputable carriers and agencies for seamless logistics.",
      },
      {
        countNum: 50,
        countName: "Global Destinations",
        countTitle: "Extending our reach to connect your cargo worldwide.",
      },
    ];

    return (
      <Fragment>
        <div className="row">
          {Data.map((value, index) => (
            <div
              className="counterup_style--1 col-lg-4 col-md-4 col-sm-6 col-12"
              key={index}
            >
              <h5 className="counter text-danger">
                <VisibilitySensor
                  onChange={this.onVisibilityChange}
                  offset={{ top: 10 }}
                  delayedCall
                >
                  <CountUp
                    end={this.state.didViewCountUp ? value.countNum : 0}
                  />
                </VisibilitySensor>
              </h5>
              <p className="description">{value.countName}</p>
              <p className="description text-justify">{value.countTitle}</p>
            </div>
          ))}
        </div>
      </Fragment>
    );
  }
}
export default CounterOne;
