import React, { Component } from "react";
import PageHelmet from "../component/common/Helmet";
import ModalVideo from "react-modal-video";
import {
  FaTwitter,
  FaInstagram,
  FaFacebookF,
  FaLinkedinIn,
} from "react-icons/fa";
import ScrollToTop from "react-scroll-up";
import { FiChevronUp } from "react-icons/fi";
import Header from "../component/header/Header";
import Footer from "../component/footer/Footer";
import BrandTwo from "../elements/BrandTwo";
import { FiCast, FiLayers, FiUsers, FiCheck } from "react-icons/fi";

const SocialShare = [
  { Social: <FaFacebookF />, link: "https://www.facebook.com/" },
  { Social: <FaLinkedinIn />, link: "https://www.linkedin.com/" },
  { Social: <FaInstagram />, link: "https://www.instagram.com/" },
  { Social: <FaTwitter />, link: "https://twitter.com/" },
];

class PortfolioDetails5 extends Component {
  constructor() {
    super();
    this.state = {
      isOpen: false,
    };
    this.openModal = this.openModal.bind(this);
  }
  openModal() {
    this.setState({ isOpen: true });
  }

  render() {
    let description = `ArinTractorwala is your best-trusted partner if you are looking for shipping services in the USA. We take pride in giving you top-notch shipping solutions that meet your export and import needs. With our cutting-edge technology, commitment to excellence, and diverse network of partners, we ensure that your goods reach to right destination efficiently and safely. We provide a wide range of shipping services that meet your needs. Whether you want to export goods, import goods, or want some logistics solutions, then ArinTractorwala is the perfect solution for you. 
Our ocean freight shipping services are designed well to transit a large number of goods across seas. We make strong relationships with shipping lines that allow us to give timely deliveries and competitive rates to our clients. When time is your priority, our air freight services give you an efficient and fast shipping solution. We have a relationship with leading airlines that ensure that your cargo is reached safely to its destination on time. Our team handles all steps of the process, from packaging and customs clearance to timely delivery. We navigate the complexity of your custom regulations and overcome challenges. Our experienced team makes the process simpler, ensures that our compliance is met with customer requirements, and reduces delays. 
We provide you the state of state-of-the-art warehousing facilities and optimize your supply chain. Our shipping services are designed well to meet your demands and ensure that your products reach your destination on time. For heavy or oversize cargo, we are experts in handling project logistics cargo. With our many years of experience, we manage your shipments, whether they consist of equipment, machinery, or industrial components. Our core shipping services add value to us and become a reason for the success of your business. We tailored your shipping services needs by giving comprehensive solutions for your export and import requirements.  
        `;
    return (
      <React.Fragment>
        <PageHelmet pageTitle="Product Details" />

        <Header
          headertransparent="header--transparent"
          colorblack="color--black"
          logoname="ATT Star Icon.png"
        />

        {/* Start Breadcrump Area */}
        <div
          className="rn-page-title-area pt--120 pb--190 bg_image bg_image--4"
          data-black-overlay="7"
        >
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <div className="rn-page-title text-center pt--100">
                  <h2 className="title theme-gradient">Shipping</h2>
                  <p>
                    “Navigating Global Trade: Your Trusted Partner in Shipping
                    Services”
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* End Breadcrump Area */}

        <div className="rn-about-area ptb--120 bg_color--1">
          <div className="rn-about-wrapper">
            <div className="container">
              <div className="row row--35 align-items-center">
                <div className="col-lg-5">
                  <div className="thumbnail">
                    <img
                      className="w-100"
                      height={"600px"}
                      src="/assets/images/about/about-52.jpg"
                      alt="About Images"
                    />
                  </div>
                </div>
                <div className="col-lg-7 text-justify">
                  <div className="about-inner inner">
                    <div className="section-title">
                      {/* <h2 className="title">{title}</h2> */}
                      <p>{description}</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* Start Back To Top */}
        <div className="backto-top">
          <ScrollToTop showUnder={160}>
            <FiChevronUp />
          </ScrollToTop>
        </div>
        {/* End Back To Top */}

        <Footer />
      </React.Fragment>
    );
  }
}
export default PortfolioDetails5;
