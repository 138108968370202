import React, { Component } from "react";
import PageHelmet from "../component/common/Helmet";
import { FiHeadphones, FiMail, FiMapPin } from "react-icons/fi";
import GoogleMapReact from "google-map-react";
import ContactTwo from "../elements/contact/ContactTwo";
import BrandTwo from "../elements/BrandTwo";
import Particles from "react-tsparticles";
import ScrollToTop from "react-scroll-up";
import { FiChevronUp } from "react-icons/fi";
import Header from "../component/header/Header";
import Footer from "../component/footer/FooterTwo";
import CallAction from "../elements/callaction/CallAction";
// import "ol/ol.css";
import { Map, View } from "ol";
import { fromLonLat } from "ol/proj";
import TileLayer from "ol/layer/Tile";
import OSM from "ol/source/OSM";

class Contact extends Component {
  componentDidMount() {
    new Map({
      target: "map",
      layers: [
        new TileLayer({
          source: new OSM(),
        }),
      ],
      view: new View({
        center: fromLonLat([-77.0366, 39.381266]),
        zoom: 10,
      }),
    });
  }

  render() {
    return (
      <React.Fragment>
        <PageHelmet pageTitle="Contact" />

        <Header
          headertransparent="header--transparent"
          colorblack="color--black"
          logoname="ATT-Logo-_JPEG_.svg"
        />
        <div
          className="rn-page-title-area ptb--40 bg_image bg_image--35"
          data-black-overlay="3"
        >
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <div className="rn-page-title text-center pt--100 pb--10">
                  <h2 className="title theme-gradient">Contact Us</h2>
                  <p className="text-center mt-3">
                    Ready to expand your horizons with seamless import-export
                    solutions? Reach out today, and let's kickstart your global
                    trade journey. We're here to assist you every step of the
                    way.
                  </p>
                  <p className="text-center">
                    Reach out to Import Export Services Limited today!
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* End Breadcrump Area */}

        {/* Start Contact Top Area  */}
        <div className="rn-contact-top-area ptb--50 bg_color--5">
          <div className="container">
            <div className="row">
              {/* Start Single Address  */}
              <div className="col-lg-4 col-md-6 col-sm-6 col-12 mt-2">
                <div
                  className="rn-address"
                  style={{
                    display: "flex",
                    paddingLeft: "20px",
                    paddingRight: "unset",
                  }}
                >
                  <div
                    className="icon"
                    style={{
                      position: "unset",
                      marginRight: "5px",
                      border: "none",
                    }}
                  >
                    <FiHeadphones />
                  </div>
                  <div className="inner">
                    <h4 className="title">Contact With Phone Number</h4>
                    <p>
                      <a href="tel: +919716823773 "> +91 9716823773 </a>
                    </p>
                    <hr />
                    {/* <p>
                      <a href="tel: +91-XXX-XXX-XXXX"> +91-XXX-XXX-XXXX</a>
                    </p> */}
                    {/* <p><a href="tel:+856 325 652 984">+856 325 652 984</a></p> */}
                    <hr />
                  </div>
                </div>
              </div>
              {/* End Single Address  */}

              {/* Start Single Address  */}
              <div className="col-lg-4 col-md-6 col-sm-6 col-12 mt_mobile--50 mt-2">
                <div
                  className="rn-address"
                  style={{
                    display: "flex",
                    paddingLeft: "20px",
                    paddingRight: "unset",
                  }}
                >
                  <div
                    className="icon"
                    style={{
                      position: "unset",
                      marginRight: "5px",
                      border: "none",
                    }}
                  >
                    <FiMail />
                  </div>
                  <div className="inner">
                    <h4 className="title">Email Address</h4>
                    <p>
                      <a href="mailto:dummy@gmail.com">dummy@gmail.com</a>
                    </p>
                    <hr />
                    <hr />
                    {/* <hr />
                    <hr /> */}
                    {/* <p><a href="mailto:example@gmail.com">example@gmail.com</a></p> */}
                  </div>
                </div>
              </div>
              {/* End Single Address  */}

              {/* Start Single Address  */}
              <div className="col-lg-4 col-md-6 col-sm-6 col-12 mt_md--50 mt_sm--50 mt-2">
                <div
                  className="rn-address"
                  style={{
                    display: "flex",
                    paddingLeft: "20px",
                    paddingRight: "unset",
                  }}
                >
                  <div
                    className="icon"
                    style={{
                      position: "unset",
                      marginRight: "5px",
                      border: "none",
                    }}
                  >
                    <FiMapPin />
                  </div>
                  <div className="inner">
                    <h4 className="title">Location</h4>
                    <p>USA, INDIA, AUS, UAE</p>
                    <hr />
                    <hr />
                  </div>
                </div>
              </div>
              {/* End Single Address  */}
            </div>
          </div>
        </div>
        {/* End Contact Top Area  */}

        {/* Start Contact Page Area  */}
        <div className="rn-contact-page ptb--120 bg_color--1">
          <ContactTwo />
        </div>
        {/* End Contact Page Area  */}

        {/* Start Contact Map  */}
        <div className="rn-contact-map-area position-relative">
          <div id="map" style={{ height: "650px", width: "100%" }}>
            {/* <Map
              layers={[
                new TileLayer({
                  source: new OSM(),
                }),
              ]}
              view={
                new View({
                  center: fromLonLat([114.1796, 22.3186]),
                  zoom: 10,
                })
              }
            /> */}
          </div>
        </div>
        <hr />
        <hr />
        <hr />
        <hr />
        {/* End Contact Map  */}

        {/* Start Brand Area */}
        {/* <div className="rn-brand-area brand-separation bg_color--5 ptb--120">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <BrandTwo />
                            </div>
                        </div>
                    </div>
                </div> */}
        {/* End Brand Area */}

        {/* Start Back To Top */}
        <div className="backto-top">
          <ScrollToTop showUnder={160}>
            <FiChevronUp />
          </ScrollToTop>
        </div>
        {/* End Back To Top */}
        {/* Start call To Action  */}
        <CallAction />
        {/* End call To Action  */}

        {/* Start Footer Style  */}
        <Footer />
        {/* End Footer Style  */}
        {/* <Footer /> */}
      </React.Fragment>
    );
  }
}
export default Contact;
