import React, { Component } from "react";
import Slider from "react-slick";
import { portfolioSlick2 } from "../../../page-demo/script";
import { Link } from "react-router-dom";

const PortfolioList = [
  {
    image: "image-1",
    category: "Blogs",
    title: "The Basics of International Trade: A Beginner's Guide",
    link: "/blog-details",
  },
  {
    image: "image-2",
    category: "Blogs",
    title: "How to Choose the Right Freight Forwarder for Your Business",
    link: "/blog-details1",
  },
  {
    image: "image-3",
    category: "Blogs",
    title: "Top 10 Export-Ready Countries for Your Business",
    link: "/blog-details2",
  },
  {
    image: "image-4",
    category: "Blogs",
    title: "The Impact of Trade Tariffs on Global Supply Chains",
    link: "/blog-details3",
  },
  {
    image: "image-3",
    category: "Blogs",
    title: "Tips for Negotiating International Trade Agreements",
    link: "/blog-details4",
  },
  //   {
  //     image: "image-4",
  //     category: "Blogs",
  //     title: "Customs Duties: How to Calculate and Minimize Costs",
  //     link: "/blog-details5",
  //   },
  //   {
  //     image: "image-1",
  //     category: "Blogs",
  //     title: "Essential Documents for Smooth International Trade",
  //     link: "/blog-details6",
  //   },
  //   {
  //     image: "image-2",
  //     category: "Blogs",
  //     title: "Choosing the Right Incoterms for Your Shipments",
  //     link: "/blog-details7",
  //   },
  //   {
  //     image: "image-3",
  //     category: "Blogs",
  //     title: "Optimizing Supply Chain Efficiency in Import-Export",
  //     link: "/blog-details8",
  //   },
  //   {
  //     image: "image-4",
  //     category: "Blogs",
  //     title: "Navigating Tariffs and Trade Barriers: A Comprehensive Guide",
  //     link: "/blog-details9",
  //   },
  //   {
  //     image: "image-3",
  //     category: "Blogs",
  //     title: "E-commerce and Global Trade: Expanding Market Reach",
  //     link: "/blog-details10",
  //   },
  //   {
  //     image: "image-4",
  //     category: "Blogs",
  //     title: "Customs Duties: How to Calculate and Minimize Costs",
  //     link: "/blog-details11",
  //   },
  //   {
  //     image: "image-1",
  //     category: "Blogs",
  //     title:
  //       "Understanding Trade Finance: Funding Options for Import-Export Businesses",
  //     link: "/blog-details12",
  //   },
  //   {
  //     image: "image-2",
  //     category: "Blogs",
  //     title:
  //       "Emerging Market Opportunities: A Look at the Next Frontier for Importers and Exporters",
  //     link: "/blog-details13",
  //   },
  //   {
  //     image: "image-3",
  //     category: "Blogs",
  //     title: "Pros and Cons of Dropshipping in International E-commerce",
  //     link: "/blog-details14",
  //   },
  //   {
  //     image: "image-4",
  //     category: "Blogs",
  //     title: "Photography and Videography Gear",
  //     link: "/blog-details15",
  //   },
  //   {
  //     image: "image-3",
  //     category: "Blogs",
  //     title: "A Deep Dive into Free Trade Zones: Benefits and Best Practices",
  //     link: "/blog-details16",
  //   },
  //   {
  //     image: "image-4",
  //     category: "Blogs",
  //     title: "The Future of Trade: Trends Shaping Global Commerce",
  //     link: "/blog-details17",
  //   },
];

class Portfolio extends Component {
  render() {
    let title = "Our Works",
      description =
        "There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration.";
    return (
      <React.Fragment>
        <div className="portfolio-wrapper">
          {/* <div className="container">
                        <div className="row">
                            <div className="col-lg-6">
                                <div className="section-title">
                                    <h2>{title}</h2>
                                    <p>{description}</p>
                                </div>
                            </div>
                        </div>
                    </div> */}
          <div className="portfolio-slick-activation mt--70 mt_sm--40">
            <Slider {...portfolioSlick2}>
              {PortfolioList.map((value, index) => (
                <div className="portfolio" key={index}>
                  <div className="thumbnail-inner">
                    <div className={`thumbnail ${value.image}`}></div>
                    <div className={`bg-blr-image ${value.image}`}></div>
                  </div>
                  <div className="content">
                    <div className="inner">
                      <p>{value.category}</p>
                      <h4 className="title">
                        <a href="/portfolio-details">{value.title}</a>
                      </h4>
                      <div className="portfolio-button">
                        <a className="rn-btn" href={value.link}>
                          Open Blog
                        </a>
                      </div>
                    </div>
                  </div>
                  <Link className="link-overlay" to="/portfolio-details"></Link>
                </div>
              ))}
            </Slider>
          </div>
        </div>
      </React.Fragment>
    );
  }
}
export default Portfolio;
