import React from "react";
import {
  FaTwitter,
  FaInstagram,
  FaFacebookF,
  FaLinkedinIn,
  FaTiktok,
} from "react-icons/fa";
import { Twitter } from "../../../public/assets/images/twitter.png";

const SocialShare = [
  {
    Social: <FaFacebookF />,
    link: "https://fb.watch/nLWn8KEyBn",
  },
  {
    Social: <FaInstagram />,
    link: "https://instagram.com/arintractorwala",
  },
  {
    Social: <FaTiktok />,
    link: "https://www.tiktok.com/@arintractorwala",
  },
  // {
  //   Social: (
  //     <svg
  //       xmlns="http://www.w3.org/2000/svg"
  //       height="1em"
  //       viewBox="0 0 512 512"
  //     >
  //       <path
  //         stroke="white"
  //         fill="grey"
  //         d="M389.2 48h70.6L305.6 224.2 487 464H345L233.7 318.6 106.5 464H35.8L200.7 275.5 26.8 48H172.4L272.9 180.9 389.2 48zM364.4 421.8h39.1L151.1 88h-42L364.4 421.8z"
  //       />
  //     </svg>
  //   ),
  //   link: "https://twitter.com/",
  // },
];

const FooterTwo = () => {
  return (
    <div
      className="footer-style-2 ptb--30 bg_image bg_image--1"
      data-black-overlay="6"
    >
      <div className="wrapper plr--50 plr_sm--20">
        <div className="row align-items-center justify-content-between">
          <div className="col-lg-4 col-md-6 col-sm-6 col-12">
            <div className="inner">
              <div className="logo text-center text-sm-left mb_sm--20">
                <a href="/home-one">
                  <img
                    src="/assets/images/logo/ATT-Logo-_JPEG_.svg"
                    width={200}
                    alt="Logo images"
                  />
                </a>
              </div>
            </div>
          </div>
          <div className="col-lg-4 col-md-6 col-sm-6 col-12">
            <div className="inner text-center">
              <ul className="social-share rn-lg-size d-flex justify-content-center liststyle">
                {SocialShare.map((val, i) => (
                  <li key={i}>
                    <a href={`${val.link}`}>{val.Social}</a>
                  </li>
                ))}
              </ul>
            </div>
          </div>
          <div className="col-lg-4 col-md-12 col-sm-12 col-12">
            <div className="inner text-lg-right text-center mt_md--20 mt_sm--20">
              <div className="text">
                <p>Copyright © 2023 ArinTractorWala. All Rights Reserved.</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default FooterTwo;
