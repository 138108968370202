import React, { Component } from "react";
import PageHelmet from "../component/common/Helmet";
import Breadcrumb from "../elements/common/Breadcrumb";
import CounterOne from "../elements/counters/CounterOne";
import Testimonial from "../elements/Testimonial";
import { videoTagString, VideoTag } from "react-video-tag";
import ModalVideo from "react-modal-video";
import ServiceList from "../elements/service/ServiceList";
import BrandTwo from "../elements/BrandTwo";
import { FaFacebookF, FaLinkedinIn, FaTwitter } from "react-icons/fa";
import Slider from "react-slick";
import { slideSlick } from "../page-demo/script";
import { FiCast, FiLayers, FiUsers, FiCheck } from "react-icons/fi";
import ScrollToTop from "react-scroll-up";
import { FiChevronUp } from "react-icons/fi";
import Header from "../component/header/Header";
import Footer from "../component/footer/Footer";
import { NavLink } from "react-router-dom";

const ServiceListOne = [
  {
    icon: "icon-01.png",
    title: "Unbeatable Wholesale Prices",
    description:
      " Our strong relationships with manufacturers and brands allow us to offer you the most competitive prices in the market. ",
  },
  {
    icon: "icon-02.png",
    title: "Global Reach",
    description:
      " Our influence extends beyond Hong Kong, catering to international clients with the same level of dedication.",
  },
  {
    icon: "icon-03.png",
    title: "Best Customer Support",
    description:
      "Our devoted support team is here to assist you at every step of the way. When you partner with us, you are guaranteed cost-effective solutions.",
  },
  {
    icon: "icon-02.png",
    title: "Reliability and Trust",
    description:
      " With our extensive industry experience, we have built a reputation for reliability and quality that you can depend on.",
  },
  {
    icon: "icon-01.png",
    title: "Pioneering Excellence",
    description:
      " As a trailblazer in wholesale tech distribution, we are committed to advancing the industry through innovation and exceptional service.",
  },
];

class About extends Component {
  constructor() {
    super();
    this.state = {
      isOpen: false,
    };
    this.openModal = this.openModal.bind(this);
  }
  openModal() {
    this.setState({ isOpen: true });
  }
  render() {
    let title = "About",
      description =
        "Welcome to Arin Tractorwala, a leading import-export service provider in the USA. With our unwavering commitment to our customers and rich history of excellence, we make us the trusted import and export service company in California, USA. Our dedication and team of experienced service providers make the success of our company and facilitate seamless global commerce.";
    return (
      <React.Fragment>
        <PageHelmet pageTitle="About" />

        <Header
          headertransparent="header--transparent"
          colorblack="color--black"
          logoname="ATT-Logo-_JPEG_.svg"
        />
        {/* Start Breadcrump Area */}
        <Breadcrumb title={"About"} />
        {/* End Breadcrump Area */}

        {/* Start About Area  */}
        <div className="rn-about-area ptb--120 bg_color--1">
          <div className="rn-about-wrapper">
            <div className="container">
              <div className="row row--35 align-items-center">
                <div className="col-lg-5">
                  <div className="thumbnail">
                    <img
                      className="w-100"
                      height={"600px"}
                      src="/assets/images/about/about-52.jpg"
                      alt="About Images"
                    />
                  </div>
                </div>
                <div className="col-lg-7 text-justify">
                  <div className="about-inner inner">
                    <div className="section-title">
                      {/* <h2 className="title">{title}</h2> */}
                      <p>{description}</p>
                    </div>
                    <hr />
                    <hr />
                    <hr />
                    <div className="row mt--30">
                      <div className="col-lg-12 col-md-12 col-sm-12 col-12">
                        <div className="section-title ">
                          <h3 className="title">Our Core Mission</h3>
                          <p>
                            At Arin tractorwala, we commit to work as the
                            leading import and export service provider. Our
                            experts work with dedication and ensure seamless
                            global trade. The Mission of ArinTractorwala is to
                            root in three core aspects: reliability, efficiency,
                            and customer satisfaction. We work hard to
                            streamline export and import services for our
                            clients and harness cutting-edge technology
                            experience. Our commitment to effective and
                            efficient solutions ensures quick custom clearance,
                            optimized supply chain management, and reduced
                            times. Our team of experts understands the
                            importance of our client's trade and makes it
                            feasible. Our mission is to become a reliable
                            partner for businesses of all kinds and provide you
                            with dependable and consistent services. We aim to
                            build long-term relationships with our clients on
                            the basis of integrity and transparency we consider
                            while working. A customer-centric approach drives
                            our company. The good thing is that we empower your
                            business to determine new horizons, expand markets,
                            and boost economic prosperity with our cutting-edge
                            export and import solutions. We set a mission to
                            meet our customer's expectations by delivering them
                            personalized support, tailored solutions, and
                            affordable services. To follow our mission, we work
                            consistently and keep updated with new industry
                            technologies and trends.
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="rn-about-area ptb--120 bg_color--5">
          <div className="container">
            <div className="row row--35 align-items-center">
              <div className="col-lg-6 order-2 order-lg-1">
                <div className="about-inner inner">
                  <div className="counterup_style--1 section-title">
                    <h2 className="" style={{ textAlign: "left" }}>
                      Our Team
                    </h2>
                    <p className=" text-justify">
                      The success of ArinTractorwala is driven by the highly
                      skilled and diverse team of experts. We are passionate
                      about the global trade and dedicated to the success of our
                      clients. Our expert team has huge experience in customs
                      compliance, logistics, customer service, and supply chain
                      management, and we provide you with top-notch solutions.
                      <hr />
                      Every member of our team brings knowledge and a wealth of
                      experience to your work and ensures success. The good
                      thing is that we believe in professional development and
                      continuous learning and ensure that our team stays updated
                      with new industry trends and regulations. Our commitment
                      to excellence permits us to give accurate advice and
                      quality solutions to our clients. We foster an inclusive
                      work and collaborative work environment where innovation
                      and creativity thrive. Our experts are encouraged to think
                      outside to box, explore new solutions, and find the best
                      to meet challenges. We value inclusivity and diversity and
                      recognize that different aspects lead to good results. At
                      ArinTractorwala, our team is not a group of people but a
                      family that works collaboratively to meet the mission and
                      vision. We are proud of the dedication of our team, our
                      personal touch, and our professionalism that makes our
                      relationship strong with our clients.
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-lg-6 order-1 order-lg-2">
                <div className="thumbnail position-relative">
                  <video
                    className="w-100"
                    src="/assets/images/about/production_id_4300496 (720p).mp4"
                    type="video/mp4"
                    alt="About Images"
                    height="600px"
                    autoPlay
                    loop
                  ></video>
                  <ModalVideo
                    channel="youtube"
                    isOpen={this.state.isOpen}
                    videoId="F_EQXphCw-8"
                    onClose={() => this.setState({ isOpen: false })}
                  />

                  <button
                    className="video-popup position-top-center theme-color"
                    onClick={this.openModal}
                  >
                    <span className="play-icon"></span>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="rn-team-area bg_color--1 ptb--120">
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <div className="section-title service-style--3 text-center mb--25 text-justify ">
                  <h2 className="title">Our Strength</h2>
                  <p className=" text-justify" style={{ padding: 0 }}>
                    At ArinTractorWala, our strength lies in our commitment to
                    dedication, excellence, and innovation. We gained a huge
                    reputation as a reputed import and export service provider
                    in the USA by following key strengths. <hr />
                    <em>Experience</em> - Our team at ArinTractorWala comprises
                    professionals who have huge years of experience and
                    knowledge. They know well how to handle logistics, supply
                    chain management, and customs regulations and make global
                    trade easier and simpler. <hr /> <em>Innovation</em> - We
                    use innovative solutions and cutting-edge technology to make
                    processes streamlined and give you cost-effective solutions.
                    Our technology investment allows us to give data analytics,
                    real-time tracking features, and effective communication to
                    our clients. <hr /> <em>Customer-centric approach</em> - We
                    give a customer-centric approach to our clients by
                    understanding their requirements closely. We take the time
                    to understand your needs, plan accordingly, and provide the
                    best solutions to meet your needs. Our commitment and
                    dedication meet customer satisfaction. <hr />{" "}
                    <em>Global network</em> - We have a team of partners,
                    stakeholders, and agents that work worldwide. This global
                    reach ensures that we provide import and export services to
                    our clients all over the world and expand the market.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="rn-team-area bg_color--1 pb--120">
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <div className="section-title service-style--3 text-center mb--25 text-justify ">
                  <h2 className="title">Our Vision</h2>
                  <p className=" text-justify" style={{ padding: 0 }}>
                    At ArinTractorwala, our vision is to become the reputed
                    export and import service provider in the USA and renowned
                    because of our innovative solutions, excellence, and
                    commitment to the success of our clients. We envision a
                    community where business is involved in international trade
                    and gains new experience. In this way, we help business to
                    overcome geographical barriers and gains sustainable growth.
                    We see the future of the entire business where we set the
                    industry standards for more reliability and efficiency in
                    global trade. We aim to become a catalyst for economic
                    growth and allow the business to dive into market trends.
                    The businesses associated with us are able to boost their
                    revenues and become more potential. Our vision expands the
                    success of our clients and partners. To remember our vision,
                    our team fosters a culture of collaboration and innovation
                    within our business. Thus, we expand the network of our
                    global partners and stakeholders and make bridges to connect
                    businesses all over the world. The Arintractorwala
                    commitment to best business practices and sustainability
                    ensures the growth of your business and huge potential for
                    the future. Thus, our vision is to lead the revolution of
                    the export and import industry and make your global trade
                    efficient, accessible, and environmentally responsible for
                    all kinds of business.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="rn-team-area bg_color--1 pb--120">
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <div className="section-title service-style--3 text-center mb--25 text-justify ">
                  <h2 className="title">Our client success stories</h2>
                  <p className=" text-justify" style={{ padding: 0 }}>
                    During the whole journey, we had the privilege of working
                    with a wide range of clients across several industries. The
                    success stories of our clients stand as a testament to our
                    value and show how we bring our client's business to a
                    global level. We help businesses to establish corporations
                    and streamline supply chain management. So, contact
                    ArinTractorwala now to get assistance at every step.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="rn-team-area bg_color--1 pb--120">
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <div className="section-title service-style--3 text-center mb--25 text-justify ">
                  <h2 className="title">Our commitment to sustainability</h2>
                  <p className=" text-justify" style={{ padding: 0 }}>
                    In today's world, sustainability is important in every
                    sector. At ArinTractorWala, we commit to providing
                    environment-friendly services to our clients. We explore
                    eco-friendly shipping options by working with dedication and
                    leading sustainable supply chain practices. In this way, we
                    commit to reducing the carbon footprint. Our expert
                    dedication to sustainability extends to our customers
                    because we assist them in adopting greener practices in
                    export and import operations.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="rn-team-area bg_color--1 pb--120">
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <div className="section-title service-style--3 text-center mb--25 text-justify ">
                  <h2 className="title">Our Values</h2>
                  <p className=" text-justify" style={{ padding: 0 }}>
                    Transparency, integrity, and a customer-centric approach are
                    the milestones of your business. We operate with high
                    standards and ensure that our interactions with our clients
                    are based on honesty and trust. Our commitment to dedication
                    and transparency means that you can rely on us always for
                    fair practices and accurate information. We believe that our
                    success depends on the success of our clients. This is the
                    reason why we place our customers at the center. We listen
                    to their needs closely, adapt to changing market conditions,
                    and strive constantly to meet client expectations. <hr /> We
                    deliver a range of export and import services to our
                    clients. We help you to find the best manufacturers and
                    suppliers worldwide. Our team of experts is able to handle
                    the paperwork and ensure a smooth process. We manage
                    everything, from logistics, shipping and customs clearance
                    for exports. Thus, we also give insights into your business
                    and help you to make informed decisions. Moreover, we also
                    ensure that your chosen suppliers meet the compliance
                    standards and highest quality. We provide efficient
                    distribution and warehousing solutions to our clients.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="rn-team-area bg_color--1 pb--120">
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <div className="section-title service-style--3 text-center mb--25 text-justify ">
                  <h2 className="title">Join Us</h2>
                  <p className=" text-justify" style={{ padding: 0 }}>
                    At ArinTractorwala, our commitment to innovation,
                    excellence, and client-centric approach makes us a leading
                    partner. We invite you to join our community to get help in
                    your global trade and reach new heights. Whether you are an
                    expert exporter importer or a beginner, you can get
                    assistance from ArinTractorwala. We look forward to giving
                    you the best export-import service solutions and
                    contributing to your business success. You can contact us
                    today to discuss how we can help your business to achieve
                    the global trade goals.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>

        <Footer />
      </React.Fragment>
    );
  }
}
export default About;
