import React, { Component } from "react";
import PageHelmet from "../component/common/Helmet";
import ModalVideo from "react-modal-video";
import {
  FaTwitter,
  FaInstagram,
  FaFacebookF,
  FaLinkedinIn,
} from "react-icons/fa";
import ScrollToTop from "react-scroll-up";
import { FiChevronUp } from "react-icons/fi";
import Header from "../component/header/Header";
import Footer from "../component/footer/Footer";
import BrandTwo from "../elements/BrandTwo";
import { FiCast, FiLayers, FiUsers, FiCheck } from "react-icons/fi";

const SocialShare = [
  { Social: <FaFacebookF />, link: "https://www.facebook.com/" },
  { Social: <FaLinkedinIn />, link: "https://www.linkedin.com/" },
  { Social: <FaInstagram />, link: "https://www.instagram.com/" },
  { Social: <FaTwitter />, link: "https://twitter.com/" },
];

class PortfolioDetails10 extends Component {
  constructor() {
    super();
    this.state = {
      isOpen: false,
    };
    this.openModal = this.openModal.bind(this);
  }
  openModal() {
    this.setState({ isOpen: true });
  }
  render() {
    return (
      <React.Fragment>
        <PageHelmet pageTitle="Product Details" />
        <Header
          headertransparent="header--transparent"
          colorblack="color--black"
          logoname="ATT Star Icon.png"
        />
        {/* Start Breadcrump Area */}
        <div
          className="rn-page-title-area pt--60 pb--60 bg_image bg_image--4"
          data-black-overlay="7"
        >
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <div className="rn-page-title text-center pt--100">
                  <h2 className="title theme-gradient">Clients</h2>
                  <p>
                    ArinTractorWala: Your Trusted Partner for Global Import and
                    Export Services
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* End Breadcrump Area */}

        <div className="rn-pricing-table-area ptb--120 bg_color--5">
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <div className="section-title service-style--3 text-center mb--25 mb_sm--0 text-justify">
                  {/* <h2 className="title">Details</h2> */}
                  <p className="text-justify" style={{ padding: "0 5%" }}>
                    ArinTractorWala is the best export and import service
                    provider in the USA, and we take huge pride in meeting the
                    unique needs of our clients. We provide you with an
                    extensive range of services, including shipping,
                    door-to-door services, packaging, warehousing, pick-up, and
                    delivery services. We have huge years of experience team of
                    experts, due to which we give commitment to our clients to
                    meet excellent levels. By working consistently, we made a
                    record of reliability and became your trusted import and
                    export service provider. Our export and import services meet
                    the unique needs of every industry we work. Whether you are
                    part of agriculture, manufacturing, automotive, technology,
                    or other sector, we covered all. One of our major strengths
                    is our global reach, which connects us with a number of
                    clients and makes a strong customer base. In this way, we
                    make strong partnerships and international connections that
                    allow us to import or export your goods seamlessly. In the
                    manufacturing field, we understand the significance of
                    efficient and timely supply chain management.
                    ArinTractorwala provides you best logistics solutions and
                    ensures that your goods and finished products are moved with
                    accuracy, improve productivity, and reduce delays. Our team
                    facilitates the flow of your products in such a way that it
                    meets the global demand.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* <div className="rn-brand-area brand-separation bg_color--5 ptb--120">
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <BrandTwo />
              </div>
            </div>
          </div>
        </div> */}
        {/* Start Back To Top */}
        <div className="backto-top">
          <ScrollToTop showUnder={160}>
            <FiChevronUp />
          </ScrollToTop>
        </div>
        {/* End Back To Top */}
        <Footer />
      </React.Fragment>
    );
  }
}
export default PortfolioDetails10;
