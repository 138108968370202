import React, { Component } from "react";
import {
  FaInstagram,
  FaFacebookF,
  FaLinkedinIn,
  FaMailBulk,
  FaPhone,
  FaTiktok,
  FaSquareXTwitter,
} from "react-icons/fa";

const SocialShare = [
  {
    Social: <FaFacebookF />,
    link: "https://fb.watch/nLWn8KEyBn",
  },
  {
    Social: <FaInstagram />,
    link: "https://instagram.com/arintractorwala",
  },
  {
    Social: <FaTiktok />,
    link: "https://www.tiktok.com/@arintractorwala",
  },
  // {
  //   Social: (
  //     <svg
  //       xmlns="http://www.w3.org/2000/svg"
  //       height="1em"
  //       viewBox="0 0 512 512"
  //       stroke="white"
  //       fill="grey"
  //     >
  //       <path d="M389.2 48h70.6L305.6 224.2 487 464H345L233.7 318.6 106.5 464H35.8L200.7 275.5 26.8 48H172.4L272.9 180.9 389.2 48zM364.4 421.8h39.1L151.1 88h-42L364.4 421.8z" />
  //     </svg>
  //   ),
  //   link: "https://twitter.com/",
  // },
];

class Footer extends Component {
  render() {
    return (
      <React.Fragment>
        <footer className="footer-area">
          <div className="footer-wrapper">
            <div className="row align-items-end row--0">
              <div className="col-lg-6">
                <div className="footer-left">
                  <div className="inner">
                    <span>Have Questions?</span>
                    <h2>
                      We're ready <br /> to help.
                    </h2>
                    <a className="rn-button-style--2" href="/contact">
                      <span>Contact Us</span>
                    </a>
                  </div>
                </div>
              </div>
              <div className="col-lg-6">
                <div className="footer-right" data-black-overlay="0">
                  <div className="row">
                    {/* Start Single Widget  */}
                    <div className="col-lg-6 col-sm-6 col-12">
                      <div className="footer-link">
                        <h4>Quick Links</h4>
                        <ul className="ft-link">
                          <li>
                            <a href="/">Home</a>
                          </li>
                          <li>
                            <a href="/about">About</a>
                          </li>
                          <li>
                            <a href="/blog">Blogs</a>
                          </li>
                          <li>
                            <a href="/contact">Contact</a>
                          </li>
                        </ul>
                      </div>
                    </div>
                    {/* End Single Widget  */}
                    {/* Start Single Widget  */}
                    <div className="col-lg-6 col-sm-6 col-12 mt_mobile--30">
                      <div className="footer-link justify-content">
                        <h4>Contact Us</h4>
                        <ul className="ft-link">
                          <li>
                            <a href="mailto: arintractorwala@gmail.com">
                              <FaMailBulk /> arintractorwala@gmail.com
                            </a>
                          </li>

                          <li>
                            <a href="tel: +919716823773 ">
                              {" "}
                              <FaPhone /> +91 9716823773
                            </a>
                          </li>
                          <br />

                          {/* <li>
                            <a href="tel: +91-XXX-XXX-XXXX" className="ml-1">
                              {" "}
                              <FaPhone /> +91-XXX-XXX-XXXX
                            </a>
                          </li> */}
                        </ul>

                        <div className="social-share-inner">
                          <ul className="social-share social-style--2 d-flex justify-content-start liststyle mt--15">
                            {SocialShare.map((val, i) => (
                              <li key={i}>
                                <a href={`${val.link}`}>{val.Social}</a>
                              </li>
                            ))}
                          </ul>
                        </div>
                      </div>
                    </div>
                    {/* End Single Widget  */}

                    <div className="col-lg-12">
                      <div className="copyright-text">
                        <p>
                          Copyright © 2023 ArinTractorWala. All Rights Reserved.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </footer>
      </React.Fragment>
    );
  }
}
export default Footer;
