import React, { Component } from "react";
import PageHelmet from "../component/common/Helmet";
import ModalVideo from "react-modal-video";
import {
  FaTwitter,
  FaInstagram,
  FaFacebookF,
  FaLinkedinIn,
} from "react-icons/fa";
import ScrollToTop from "react-scroll-up";
import { FiChevronUp } from "react-icons/fi";
import Header from "../component/header/Header";
import Footer from "../component/footer/Footer";
import BrandTwo from "../elements/BrandTwo";
import { FiCast, FiLayers, FiUsers, FiCheck } from "react-icons/fi";

const SocialShare = [
  { Social: <FaFacebookF />, link: "https://www.facebook.com/" },
  { Social: <FaLinkedinIn />, link: "https://www.linkedin.com/" },
  { Social: <FaInstagram />, link: "https://www.instagram.com/" },
  { Social: <FaTwitter />, link: "https://twitter.com/" },
];

class PortfolioDetails11 extends Component {
  constructor() {
    super();
    this.state = {
      isOpen: false,
    };
    this.openModal = this.openModal.bind(this);
  }
  openModal() {
    this.setState({ isOpen: true });
  }
  render() {
    return (
      <React.Fragment>
        <PageHelmet pageTitle="Product Details" />

        <Header
          headertransparent="header--transparent"
          colorblack="color--black"
          logoname="ATT Star Icon.png"
        />

        {/* Start Breadcrump Area */}
        <div
          className="rn-page-title-area pt--120 pb--190 bg_image bg_image--4"
          data-black-overlay="7"
        >
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <div className="rn-page-title text-center pt--100">
                  <h2 className="title theme-gradient">Videos</h2>
                  <p>
                    Lorem ipsum dolor sit amet consectetur adipisicing elit.
                    Eveniet nostrum amet facere illo ratione error, ex assumenda
                    voluptas fugit sed culpa hic iusto non rerum quam! Sapiente
                    repellendus nihil consectetur.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* End Breadcrump Area */}

        {/* <div className="rn-pricing-table-area ptb--120 bg_color--5">
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <div className="section-title service-style--3 text-center mb--25 mb_sm--0 text-justify">
                  <h2 className="title">Details</h2>
                  <p className="text-justify">
                    Lorem ipsum dolor sit amet consectetur adipisicing elit.
                    Temporibus ipsam, molestias illo ea amet tempore
                    consequuntur magnam quis repellat! Debitis nam esse quos et,
                    culpa praesentium sit eaque voluptas cum? Earum similique
                    minus sint aliquid tenetur consequatur quaerat quas numquam
                    maiores ullam. Sint suscipit facilis dolorum quae at, quia
                    voluptatem eos. Tempore tenetur, minima dolorem quis
                    consectetur deserunt error adipisci. Placeat ab esse
                    corporis sed ipsam recusandae saepe commodi repellendus
                    alias expedita debitis, quis voluptatem hic doloribus odit
                    suscipit labore laborum repudiandae? Similique, laudantium
                    enim illum iste officia deserunt quod? Ipsum maiores magnam
                    necessitatibus velit nostrum rem voluptates quam sit minima
                    recusandae aspernatur, omnis atque, nihil ratione aliquam
                    maxime cupiditate quaerat repudiandae distinctio natus
                    incidunt repellendus, neque tempora? Dolores, tempora.
                    Maiores, atque molestiae fugiat, cum laborum, harum
                    dignissimos iusto tempore ullam consectetur velit. Possimus
                    a accusantium, corrupti, itaque laboriosam tempora sapiente
                    perferendis, aliquid soluta debitis facere error quidem unde
                    perspiciatis?
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div> */}

        {/* Start Back To Top */}
        <div className="backto-top">
          <ScrollToTop showUnder={160}>
            <FiChevronUp />
          </ScrollToTop>
        </div>
        {/* End Back To Top */}

        <Footer />
      </React.Fragment>
    );
  }
}
export default PortfolioDetails11;
