import React, { Component } from "react";
import PageHelmet from "../component/common/Helmet";
import ModalVideo from "react-modal-video";
import {
  FaTwitter,
  FaInstagram,
  FaFacebookF,
  FaLinkedinIn,
} from "react-icons/fa";
import ScrollToTop from "react-scroll-up";
import { FiChevronUp } from "react-icons/fi";
import Header from "../component/header/Header";
import Footer from "../component/footer/Footer";
import BrandTwo from "../elements/BrandTwo";
import { FiCast, FiLayers, FiUsers, FiCheck } from "react-icons/fi";

const SocialShare = [
  { Social: <FaFacebookF />, link: "https://www.facebook.com/" },
  { Social: <FaLinkedinIn />, link: "https://www.linkedin.com/" },
  { Social: <FaInstagram />, link: "https://www.instagram.com/" },
  { Social: <FaTwitter />, link: "https://twitter.com/" },
];

class PortfolioDetails9 extends Component {
  constructor() {
    super();
    this.state = {
      isOpen: false,
    };
    this.openModal = this.openModal.bind(this);
  }
  openModal() {
    this.setState({ isOpen: true });
  }
  render() {
    let description = `We at ArinTractorWala take huge pride in giving you top-notch warehouse facilities. We have a team of experts who work with pure dedication and provide you with seamless supply chain solutions. Our warehouses are strategically positioned in cities across the USA. This placement permits us to handle the import and export of goods efficiently. We ensure the cost-effective and quick distribution of goods to destinations. Whether you want to get short-term storage solutions or want to store your goods for a long time,  our warehousing facilities meet your needs. ArinTractorwala warehouses boast enough interiors that are well-designed to accommodate various products, from small products to large-size products. With our flexible storage configurations and racking systems, we store and arrange your goods efficiently. This versatility expands the temperature control storage options for goods that need particular environmental conditions. 
We take the security factor in the export and import industry and take this seriously. Our warehouses use cutting-edge systems for security, including access control, 24/7 surveillance, and alarm systems. These measures ensure the integrity and security of your goods during storage at our warehouses. The good thing about our warehousing facilities is our efficient inventory management that ensures successful export and import service solutions. Our warehousing facilities are equipped with inventory management advanced systems and give you real-time versatility. It allows our team to track shipments and inventory levels and make informed decisions to streamline the supply chain. Our experience and skilled workforce ensure that your goods are handled with utmost precision and care. Our logistics experts work well to ensure that your goods are promptly shipped and accurately to the right destinations.  `;
    return (
      <React.Fragment>
        <PageHelmet pageTitle="Product Details" />

        <Header
          headertransparent="header--transparent"
          colorblack="color--black"
          logoname="ATT Star Icon.png"
        />

        {/* Start Breadcrump Area */}
        <div
          className="rn-page-title-area pt--120 pb--190 bg_image bg_image--4"
          data-black-overlay="7"
        >
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <div className="rn-page-title text-center pt--100">
                  <h2 className="title theme-gradient">Warehouse Facility</h2>
                  <p>
                    “Optimal Storage Solutions: Our State-of-the-Art Warehouse
                    Facilities in the USA”
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* End Breadcrump Area */}

        <div className="rn-about-area ptb--120 bg_color--1">
          <div className="rn-about-wrapper">
            <div className="container">
              <div className="row row--35 align-items-center">
                <div className="col-lg-5">
                  <div className="thumbnail">
                    <img
                      className="w-100"
                      height={"600px"}
                      src="/assets/images/about/about-52.jpg"
                      alt="About Images"
                    />
                  </div>
                </div>
                <div className="col-lg-7 text-justify">
                  <div className="about-inner inner">
                    <div className="section-title">
                      {/* <h2 className="title">{title}</h2> */}
                      <p>{description}</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* Start Back To Top */}
        <div className="backto-top">
          <ScrollToTop showUnder={160}>
            <FiChevronUp />
          </ScrollToTop>
        </div>
        {/* End Back To Top */}

        <Footer />
      </React.Fragment>
    );
  }
}
export default PortfolioDetails9;
