import React, { Component } from "react";
import { Link } from "react-router-dom";
import { FiX, FiMenu } from "react-icons/fi";

import {
  FaTwitter,
  FaInstagram,
  FaFacebookF,
  FaLinkedinIn,
  FaTiktok,
} from "react-icons/fa";
import { Twitter } from "../../../public/assets/images/twitter.png";

const SocialShare = [
  {
    Social: <FaFacebookF />,
    link: "https://fb.watch/nLWn8KEyBn/?mibextid=qC1gEa",
  },
  {
    Social: <FaInstagram />,
    link: "https://instagram.com/arintractorwala",
  },
  {
    Social: <FaTiktok />,
    link: "https://www.tiktok.com/@arintractorwala",
  },
  // {
  //   Social: (
  //     <svg
  //       xmlns="http://www.w3.org/2000/svg"
  //       height="1em"
  //       viewBox="0 0 512 512"
  //     >
  //       <path
  //         stroke="white"
  //         fill="grey"
  //         d="M389.2 48h70.6L305.6 224.2 487 464H345L233.7 318.6 106.5 464H35.8L200.7 275.5 26.8 48H172.4L272.9 180.9 389.2 48zM364.4 421.8h39.1L151.1 88h-42L364.4 421.8z"
  //       />
  //     </svg>
  //   ),
  //   link: "https://twitter.com/",
  // },
];

class Header extends Component {
  constructor(props) {
    super(props);
    this.menuTrigger = this.menuTrigger.bind(this);
    this.CLoseMenuTrigger = this.CLoseMenuTrigger.bind(this);
    //  this.subMetuTrigger = this.subMetuTrigger.bind(this);
    window.addEventListener("load", function () {
      console.log("All assets are loaded");
    });
  }

  menuTrigger() {
    document.querySelector(".header-wrapper").classList.toggle("menu-open");
  }

  CLoseMenuTrigger() {
    document.querySelector(".header-wrapper").classList.remove("menu-open");
  }

  render() {
    var elements = document.querySelectorAll(".has-droupdown > a");
    for (var i in elements) {
      if (elements.hasOwnProperty(i)) {
        elements[i].onclick = function () {
          this.parentElement
            .querySelector(".submenu")
            .classList.toggle("active");
          this.classList.toggle("open");
        };
      }
    }
    const { logo, color = "default-color" } = this.props;
    let logoUrl;
    if (logo === "light") {
      logoUrl = (
        <img
          src="/assets/images/logo/ATT-Logo-_JPEG_.svg"
          width={145}
          alt="Digital Agency"
        />
      );
    } else if (logo === "dark") {
      logoUrl = (
        <img
          src="/assets/images/logo/ATT-Logo-_JPEG_.svg"
          width={145}
          alt="Digital Agency"
        />
      );
    } else if (logo === "symbol-dark") {
      logoUrl = (
        <img
          src="/assets/images/logo/ATT Star Icon.png"
          width={145}
          alt="Digital Agency"
        />
      );
    } else if (logo === "symbol-light") {
      logoUrl = (
        <img
          src="/assets/images/logo/ATT Star Icon.png"
          width={145}
          alt="Digital Agency"
        />
      );
    } else {
      logoUrl = (
        <img
          src="/assets/images/logo/ATT-Logo-_JPEG_.svg"
          width={145}
          alt="Digital Agency"
        />
      );
    }

    return (
      <header
        className={`header-area formobile-menu header--transparent ${color}`}
      >
        <div
          className="header-wrapper"
          id="header-wrapper"
          style={{ paddingTop: "7px" }}
        >
          <div className="header-left">
            <div className="logo">
              <a href="/">{logoUrl}</a>
            </div>
          </div>
          <div className="header-right">
            <nav className="mainmenunav d-lg-block">
              <ul className="mainmenu">
                <li>
                  <Link to="/">Home</Link>
                </li>

                <li>
                  <Link to="/about">About</Link>
                </li>

                <li className="has-droupdown">
                  <Link to="/service">Services</Link>
                  <ul className="submenu">
                    <li>
                      <Link to="/shipping">Shipping</Link>
                    </li>
                    <li>
                      <Link to="/packaging">Packaging</Link>
                    </li>
                    <li>
                      <Link to="/door-to-door">Door to Door</Link>
                    </li>
                    <li>
                      <Link to="/pickup-n-delivery">Pickup and Delivery</Link>
                    </li>
                    <li>
                      <Link to="/warehouse-facility">
                        Warehouse Facility (USA)
                      </Link>
                    </li>
                  </ul>
                </li>

                <li>
                  <Link to="/blog">Blogs</Link>
                </li>

                {/* <li className="has-droupdown">
                  <Link to="#">Media</Link>
                  <ul className="submenu">
                    <li>
                      <Link to="/media-video">Videos</Link>
                    </li>
                    <li>
                      <Link to="/media-image">Images</Link>
                    </li>
                  </ul>
                </li> */}

                <li>
                  <Link to="/client">Clients</Link>
                </li>

                <li style={{ marginRight: "50px" }}>
                  <Link to="/contact">Contact</Link>
                </li>

                {SocialShare.map((val, i) => (
                  <li key={i}>
                    <a href={`${val.link}`}>{val.Social}</a>
                  </li>
                ))}
              </ul>
            </nav>
            {/* <div className="header-btn">
                            <a className="rn-btn" href="https://themeforest.net/checkout/from_item/25457315?license=regular">
                                <span>buy now</span>
                            </a>
                        </div> */}
            {/* Start Humberger Menu  */}
            <div className="humberger-menu d-block d-lg-none pl--20">
              <span
                onClick={this.menuTrigger}
                className="menutrigger text-white"
              >
                <FiMenu />
              </span>
            </div>
            {/* End Humberger Menu  */}
            <div className="close-menu d-block d-lg-none">
              <span onClick={this.CLoseMenuTrigger} className="closeTrigger">
                <FiX />
              </span>
            </div>
          </div>
        </div>
      </header>
    );
  }
}
export default Header;
